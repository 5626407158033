<template>
  <custom-select
    v-bind="$attrs"
    v-on="$listeners"
    :options="bankOptions"
    :value="value"
  />
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    valueType: {
      type: String,
      default: 'code',
    },
  },
  computed: {
    ...mapState(['banks']),
    bankOptions() {
      return this.banks.map((bank) => ({
        title: bank.name,
        value: bank[this.valueType],
      }));
    },
  },
};
</script>
